// import StorageService from './storage';

import PWAManager from '../../assets/javascripts/modules/pwaManager';

import api from '@/actions/api';
import Utils from '@/modules/utils';

import {dot} from '@/components/themes/frontend';


class AdminManager {
    _systems = null;

    _isSystemValid = null;

    /* Initialization
    ******************** */
    initialize = () => {
        if (!import.meta.env.PROD) {
            return;
        }

        this._getSystemsStatus();

        this._handleClearPWACache();
    };

    /* Private methods
    ******************** */
    _updateAdminMenu = () => {
        const systemMenuElement = document.getElementById('admin-menu-systems');
        if (!systemMenuElement) {
            return;
        }

        const systemListElement = document.createElement('ul');
        systemListElement.className = 'py-2';

        Object.entries(this._systems)
            .forEach(([systemName, systemStatus]) => {
                const systemUnitListElement = document.createElement('li');

                const systemUnitElement = document.createElement('a');
                systemUnitElement.href = '#';
                systemUnitElement.className = 'inline-flex items-center justify-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200';
                systemUnitElement.innerHTML = systemName;

                systemUnitElement.prepend(dot((systemStatus.system === false || systemStatus.url === false) ? 'bg-red-600' : 'bg-green-600'));

                systemUnitListElement.appendChild(systemUnitElement);
                systemListElement.appendChild(systemUnitListElement);
            });

        systemMenuElement.appendChild(systemListElement);
    };

    _updateAdminStatusMenu = () => {
        const adminMenuIconElement = document.getElementById('admin-menu-icon');
        if (!adminMenuIconElement) {
            return;
        }

        const dotElement = dot(this._isSystemValid ? 'bg-green-600' : 'bg-red-600', !this._isSystemValid);
        dotElement.classList.add('absolute', 'top-[-3px]', 'left-[15px]');

        adminMenuIconElement.after(dotElement);
    };

    _getSystemsStatus = () => {
        api.post('/admins/health-check')
            .then((statuses) => {
                this._systems = statuses && statuses.error ? {} : statuses;
                this._isSystemValid = statuses?.error ? false : (statuses && !Object.values(statuses)
                    .some((status) => status && (status.system === false || status.url === false)));

                if (!this._systems || !Object.keys(this._systems).length) {
                    return;
                }

                this._updateAdminMenu();

                this._updateAdminStatusMenu();
            });
    };

    _handleClearPWACache = () => {
        const clearCacheButtonElement = document.getElementById('admin-menu-clear-cache');
        if (!clearCacheButtonElement) {
            return;
        }

        clearCacheButtonElement.onclick = (event) => {
            event.preventDefault();

            PWAManager.clearServiceWorkerCaches(() => {
                Utils.forcePageReload(window.location);
            });
        };
    };
}

export default new AdminManager();
