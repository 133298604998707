import flowbiteInitializer from '@/components/layout/flowbite';
import Utils from '@/modules/utils';
import {manageDrawer} from '@/modules/drawer';

import TrackingManager from '@/managers/trackingManager';
import UserManager from '@/managers/userManager';
import BasketManager from '@/managers/basketManager';
import TrialManager from '@/managers/trialManager';

import Notification from '@/components/layout/notification';
import {initializeConnection} from '@/components/layout/connection';

import RouteManager from '../../assets/javascripts/modules/routeManager';
import AnalyticsService from '../../assets/javascripts/modules/analyticsService';
import PWAManager from '../../assets/javascripts/modules/pwaManager';

// Define settings and features
window.settings = JSON.parse(document.getElementById('settings')?.innerText || '{}');
window.features = JSON.parse(document.getElementById('features')?.innerText || '{}');

// Check user and admin state
window.currentUserId = Utils.getCookie('user.connected_id');
window.currentAdminId = Utils.getCookie('admin.connected_id');

// Clean timestamp after connection
Utils.clearForceReloadParam();

// initialize components based on data attribute selectors
flowbiteInitializer();

manageDrawer('drawer-navigation', 'drawer-navigation-button', 'drawer-navigation-close');

// Initialize notifications
Notification.initialize();

// Initialize connection modules
initializeConnection();

// Initialize routes
RouteManager.initialize();

// Tracking state management
TrackingManager.initialize();

// User management
UserManager.initialize();

// Basket management
BasketManager.initialize();

// Analytics
AnalyticsService.initialize();

// PWA manager
PWAManager.initialize();

// Trial
TrialManager.initialize();

if (window.currentAdminId) {
    import('@/managers/adminManager')
        .then(({default: AdminManager}) => {
            AdminManager.initialize();
        });
}
